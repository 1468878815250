import React from "react"
import logo from "../img/logo.png"

import * as styles from "./logo.module.scss"

export const Logo = () => (
  <div className={styles.logo}>
    <img src={logo} alt="логотип Совкомбанк" />
  </div>
)

import React from "react"

export const CircularProgress = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.8631 35.6057C62.9158 35.7328 63.6721 36.6908 63.4821 37.734C62.391 43.7252 59.6095 49.2953 55.4472 53.7768C50.8484 58.7284 44.8001 62.0969 38.1691 63.3997C31.5381 64.7025 24.6649 63.8726 18.5346 61.029C12.4043 58.1854 7.33153 53.474 4.04343 47.5702C0.755334 41.6663 -0.579325 34.8731 0.230743 28.1641C1.04081 21.4551 3.95402 15.1747 8.55284 10.2232C13.1517 5.27161 19.2 1.90304 25.831 0.600271C31.8325 -0.578839 38.0325 -0.0109408 43.7005 2.21578C44.6874 2.60351 45.1037 3.75082 44.6575 4.71276C44.2113 5.6747 43.0717 6.08613 42.0816 5.70649C37.1521 3.81636 31.7769 3.3455 26.5712 4.36824C20.736 5.51468 15.4135 8.47901 11.3665 12.8364C7.31954 17.1938 4.75591 22.7205 4.04305 28.6244C3.33019 34.5283 4.50469 40.5064 7.39822 45.7018C10.2918 50.8971 14.7558 55.0431 20.1504 57.5455C25.5451 60.0479 31.5935 60.7782 37.4288 59.6318C43.264 58.4853 48.5866 55.521 52.6335 51.1636C56.2438 47.2764 58.6736 42.4586 59.662 37.2725C59.8605 36.2309 60.8104 35.4786 61.8631 35.6057Z"
      fill="#003791"
    />
  </svg>
)

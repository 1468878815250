import React from "react"
import { PageProps } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../../components/Layouts/uploadLayout"
import { VerifySMS } from "../../components/Upload/VerifySMS"

type LocationState = {
  token: string
}

export default function VerifyPhonePage({ location }: PageProps<null, null, LocationState>) {
  const token = location?.state?.token
  const isMobTab = useMediaQuery("(max-width:959.5px)")
  return (
    <Layout anotherBackgroundColor={isMobTab ? "white" : "standard"}>
      <VerifySMS token={token} />
    </Layout>
  )
}

// extracted by mini-css-extract-plugin
export var btn = "verifySMS-module--btn--gEaNA";
export var btnCancel = "verifySMS-module--btnCancel--OyM5A";
export var btnChange = "verifySMS-module--btnChange--CrRNy";
export var btnFlex = "verifySMS-module--btnFlex--WlI-8";
export var btnSave = "verifySMS-module--btnSave--bVNt1";
export var changePhone = "verifySMS-module--changePhone--6eHXl";
export var container = "verifySMS-module--container--ft33M";
export var containerFluid = "verifySMS-module--containerFluid--cQleZ";
export var hintSended = "verifySMS-module--hintSended--cfNTu";
export var section = "verifySMS-module--section--Ml0cj";
export var title = "verifySMS-module--title--ycQW+";
export var titleApprove = "verifySMS-module--titleApprove--IGxhW";
import React from "react"

import { navigate } from "gatsby"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import { ExitButtonIcon } from "../../../icons"
import { setToken } from "../../Upload/api/api"

import * as styles from "./exitButton.module.scss"

const exitAccount = () => {
  setToken("")
  return navigate("/upload/")
}

export const ExitButton = () => {
  const isMobTab = useMediaQuery("(max-width:959.5px)")
  return (
    <button type="button" className={styles.exitButton} onClick={exitAccount}>
      {!isMobTab && "Выйти"}
      <ExitButtonIcon />
    </button>
  )
}

import React from "react"

import * as styles from "./helperText.module.scss"

type HelperTextProps = {
  isRunning: boolean
  timer: number
  onClick: () => void
}

export function HelperText({ isRunning, timer, onClick }: HelperTextProps) {
  return (
    <div className={styles.helperText}>
      {isRunning ? (
        <span className={styles.timer}>Отправим код повторно через {timer} секунд</span>
      ) : (
        <button type="button" className={styles.resend} onClick={onClick}>
          Отправить код еще раз
        </button>
      )}
    </div>
  )
}

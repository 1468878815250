const API = process.env.GATSBY_APP_SEND_SMS || "https://api-app.sovcombank.ru"

export const sendRequest = async (data: Record<string, string>, url = "/phone/sendsmscode") => {
  try {
    return await fetch(`${API}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"

import OtpInputs from "@ecom/ui/components/OtpInputs"
import Container from "@ecom/ui/components/Container"

import { HelperText } from "./HelperText"

import { getIDBValue } from "../../../utils/idbUtils"

import { sendRequest } from "../helpers/sendRequest"
import { setToken } from "../api/api"

import * as styles from "./verifySMS.module.scss"

const TIMER_START = 45

interface IVerifySMS {
  token: string
}

const STATUS_VERIFY = {
  NOTVERIFIED: "not verified",
  SENDED: "sended",
  VERIFIED: "verified",
  LIMIT: "limit",
} as const

type TStatus = (typeof STATUS_VERIFY)[keyof typeof STATUS_VERIFY]

export function VerifySMS({ token }: IVerifySMS) {
  const [userPhone, setUserPhone] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [errorMsg, setErrorMsg] = useState("Неправильно введён код из СМС")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState("")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    getIDBValue<string>("phone").then((phone) => {
      if (phone) {
        setUserPhone(phone)
      }
    })
  }, [])

  useEffect(() => {
    let timerID: ReturnType<typeof setInterval>
    if (time > 0) {
      timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => clearTimeout(timerID)
  })

  function clearPhone() {
    setCode("")
  }

  function getNewCode(phone: string) {
    clearPhone()
    setIsError(false)
    sendRequest({ phone, source: "credit/my_account" }, "/phone/sendsmscode")
      .then(({ message }) => {
        if (message === STATUS_VERIFY.NOTVERIFIED) {
          setIsError(true)
          setTimeout(() => clearPhone(), 500)
          setIsSending(false)
          return setErrorMsg("Превышен лимит запросов")
        }

        if (message === STATUS_VERIFY.SENDED) {
          return setTime(TIMER_START)
        }

        navigate("/error/")
        return null
      })
      .catch(() => {
        setTime(0)
        setIsError(true)
        navigate("/error/")
      })
  }

  const submitCode = useCallback(
    (smsCode: string) => {
      setIsSending(true)
      setIsError(false)
      sendRequest({ code: smsCode, token, source: "credit/my_account" }, "/phone/validate")
        .then(({ message, tokenWithData }: { message: TStatus; tokenWithData: string }) => {
          if (message === STATUS_VERIFY.VERIFIED) {
            setToken(tokenWithData)
            return navigate("/upload/uploading-documents/")
          }

          setIsError(true)
          setTimeout(() => clearPhone(), 500)
          setIsSending(false)

          switch (message) {
            case STATUS_VERIFY.LIMIT:
              return setErrorMsg("Превышен лимит запросов")
            default:
              return setErrorMsg("Неправильно введён код из СМС")
          }
        })
        .catch(() => {
          setIsError(true)
          navigate("/error/")
        })
    },
    [token]
  )

  useEffect(() => {
    if (isError && code.length >= 1) {
      setIsError(false)
    }

    if (code.length === 4) {
      submitCode(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  const subtitle = (
    <>
      Отправили код подтверждения на&nbsp;номер&nbsp;
      <br />
      {userPhone}
    </>
  )

  function changePhone() {
    return navigate("/upload/")
  }

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.containerFluid}>
          <h2 className={styles.title}>Введите код</h2>
          {userPhone && <p className={styles.hintSended}>{subtitle}</p>}
          <OtpInputs
            code={code}
            setCode={setCode}
            error={isError}
            errorMsg={errorMsg}
            disabled={isSending}
            helperText={
              <HelperText
                isRunning={time !== 0}
                timer={time}
                onClick={() => getNewCode(userPhone)}
              />
            }
          />
          <button type="button" onClick={changePhone} className={styles.changePhone}>
            Войти с другим номером
          </button>
        </div>
      </Container>
    </section>
  )
}

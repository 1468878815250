import React from "react"

import Container from "@ecom/ui/components/Container"
import clsx from "clsx"

import { Logo } from "./Logo"
import { ExitButton } from "./ExitButton"

import * as styles from "./header.module.scss"

interface IHeader {
  showExitButton: boolean
  anotherBackgroundColor?: "standard" | "white"
}

export const Header = ({ showExitButton, anotherBackgroundColor = "standard" }: IHeader) => (
  <header className={clsx(styles.header, styles[anotherBackgroundColor])}>
    <Container className={styles.container}>
      <Logo />
      {showExitButton && <ExitButton />}
    </Container>
  </header>
)

import React from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { blueTheme } from "@ecom/ui/styles/themes/blue"

import { SEO, SEOProps } from "../seo"
import { Header } from "../HeaderUpload/Header"

export interface BaseLayoutProps extends SEOProps {
  children: React.ReactNode
  showExitButton?: boolean
  anotherBackgroundColor?: "standard" | "white"
}

export default function Layout({
  children,
  title,
  description,
  showExitButton = false,
  anotherBackgroundColor,
}: BaseLayoutProps) {
  return (
    <ThemeProvider theme={blueTheme}>
      <SEO title={title} description={description} />
      <Header showExitButton={showExitButton} anotherBackgroundColor={anotherBackgroundColor} />
      {children}
    </ThemeProvider>
  )
}
